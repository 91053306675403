.Compare-trade {
  /* padding-left: 80px;
  padding-right: 43px; */
  /* padding-top: 10px; */
  /* padding-bottom: 20px; */
  margin-top: 0px !important;
}

.card-layout {
  background: rgba(221, 221, 221, 0.06) !important;
  border: 1px solid rgba(116, 120, 141, 0.3) !important;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2) !important;
  border-radius: 10px !important;
}

/* Input fields */

.grid-container {
  display: flex;
  justify-content: center;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 20px;
  width: 100%;
}

.upload-container {
  height: 15vh;
  padding: 8px;
  border: 0.5px dashed rgba(116, 120, 141, 0.5);
  background: rgba(116, 120, 141, 0.05);
  margin: 5px;
  text-align: center;
  position: relative;
  border-radius: 5px;
}

.upload-input {
  display: none;
}

.upload-label {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-text {
  padding: 5px 5px;
  text-align: center;
}

.file-info {
  margin-top: 8px;
  font-size: 10px;
  color: #666;
}

.file-info-text {
  margin: 0px;
  padding: 0px;
}

.compare-button-container {
  margin-top: 20px;
  display: block;
}

.compare-button {
  border-radius: 15px;
  padding: 10px 20px;
  background-color: #175CC2;
  color: #fff;
  cursor: pointer;
  border: none;
}

.file-upload-img {
  background: rgba(23, 92, 194, .5);
  border: none;
  border-radius: 5px;
}

.uploaded-file-block {
  background-color: white;
  border-radius: 5px;
  padding: 8px;
  margin: 5px 0;
  height: 80px;
  font-family: "Open Sans";
}

.uploaded-file-text {
  color: #74788d;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 16px !important;
  text-align: left;
}

.cancel-button {
  background-color: rgba(240, 95, 95, .149) !important;
  border: none !important;
  color: #f05f5f !important;
  font-weight: 700 !important;
}