.PolicyPage {
  /* padding-left: 280px; */
  /* padding-right: 43px; */
  /* padding-top: 10px; */
  /* padding-bottom: 20px; */
  position: relative;
  margin-top: 0px !important;
}

.PolicyIPSCard {
  border-radius: 10px !important;
  color: "#34475A";
  line-height: 54px !important;
  border: 1px solid rgba(116, 120, 141, 0.3) !important;
  box-shadow: "0px 1px 8px rgba(60, 60, 60, 0.15)" !important;
}

.PolicyIPSCardDesc {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #34475A !important;
}

.list-item {
  display: flex;
  align-items: baseline !important;
}

.list-item-icon {
  width: 8.4px;
  min-width: 8.4px !important;
  height: 8px;
  background-color: #7bb1e4;
  border-radius: 50%;
  margin-right: 16px;
}

.list-item-text {
  margin-left: 0;
  margin-right: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.download-card {
  width: 346px;
  height: 92.27px;
  background: #FAFAFA;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
  border-radius: 10px;
}

.download-icon-container {
  width: 57.67px;
  height: 57.67px;
  background: #7BB1E4;
  opacity: 0.2;
  border-radius: 10px;
}

.filename {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #74788D;
}

.file-size {
  font-weight: 400;
  font-size: 13.84px;
  line-height: 18px;
  color: #74788D;

}

@media screen and (max-width: 600px) {
  .PolicyPage {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 601px) and (max-width: 1400px) {
  .PolicyPage {
    /* padding-left: 80px;
    padding-right: 40px; */
  }
}

.pie-chart-card {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}