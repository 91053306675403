.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 50px;
	background: #002A59;
	transition: width 0.5s ease;
	z-index: 99;
	overflow: visible;

	.sidebar-toggle-btn {
		display: none;
	}

	&.open {
		width: 240px;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	&:hover {
		.sidebar-toggle-btn {
			display: inline-flex;
		}

		cursor: pointer;
	}

	&.open {
		.logo {
			display: block;
			text-align: center;

			img {
				margin-block: 16px 0px;
			}
		}

		.logo-tablet {
			display: none;
		}
	}

	&:not(.open) {
		.logo {
			display: none;
			text-align: center;
		}

		.logo-tablet {
			display: block;
			text-align: center;

			img {
				height: 25px;
				width: 25px;
				margin-block: 16px 0px;
			}
		}
	}

	&:not(.open) .sub-menu.collapsed {
		display: none;
		transition: all 0.6s ease;
	}

	.menu-list {
		list-style: none;
		overflow-x: hidden;
		overflow-y: scroll;
		margin-top: 0;
		height: calc(100vh - 60px);
		scrollbar-width: none;

		.menu-item {
			display: flex;
			gap: 16px;
			padding: 6px 16px;
			white-space: nowrap;
			cursor: pointer;

			.img {
				width: 10px;
			}

			.sub-menu.collapsed.show {
				display: block;
				transition: all 0.6s ease;
			}

			.menu-link {
				font-weight: 500 !important;
				font-size: 16px;
				line-height: 21px;
				white-space: nowrap;
				text-decoration: none;
			}
		}

		.sub-menu {

			.menu-item {
				padding: 6px 0px 6px 50px;

				&:first-child {
					margin-top: 0px;
				}
			}
		}
	}
}

.arrow-icon {
	visibility: hidden;
}

.sidebar.open .arrow-icon {
	visibility: visible;
	position: absolute;
	left: 208px;
}

.menubtn {
	background: transparent;
	border: none;
}

.rotate-0 {
	transform: rotate(0deg);
}

.rotate-90degn {
	transform: rotate(-90deg);
}

.right-8n {
	right: -8px;
}

@media screen and (max-width:600px) {
	.sidebar {
		display: none;
		.sidebar-toggle-btn {
			display: none !important;
		}

		.menu-list {
			padding-top: 0;
			margin-top: 0;
		}
		:hover{
			.sidebar-toggle-btn{
				display: none !important;
			}
		}
	}

	.sidebar.open {
		display: block;
		transition: all 0.5s ease-in;
	}

	.main-content {
		padding-top: 65px;
	}
}

@media screen and (min-width: 1401px) and (max-width: 1719px) {

	body[data-legacy-view="true"] {
		.sidebar {
			left: 0;
		}
	}

}

@media screen and (min-width:601px) and (max-width:1400px) {
	.sidebar {
		left: 0;
	}
}

@media screen and (min-width:1720px) {
	.sidebar {
		left: calc((100% - 1720px) / 2);
	}

	body[data-legacy-view="true"] {
		.sidebar {
			left: 0;
		}
	}
}

@media screen and (min-width: 601px) {
	.sidebar.open~#main-content {
		padding-left: 270px;
		transition: padding 0.5s ease;
	}

	.sidebar~#main-content {
		padding-left: 80px;
		transition: padding 0.5s ease;
	}

	.sidebar.open~.app-bar {
		margin-left: 240px;
		transition: margin 0.5s ease;
	}

	.sidebar~.app-bar {
		margin-left: 50px;
		transition: margin 0.5s ease;
	}
}